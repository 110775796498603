.blog-home-main {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 11rem 3rem;
    background: #f7f8fa;
}

.blog-home-wrap {
    max-width: 1100px;
}