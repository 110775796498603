.article-main-dev {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 11rem 0rem;
    background: #f7f8fa;
}

.article-grid {
    background-color: #f7f8fa;
    max-width: 1000px;
}

.article-list-li {
    padding: 1rem;
    display: flex;
    flex-direction: row;
}

.article-imgBx {
    width: 170px;
    height: 100px;
    position: absolute;
    overflow: hidden;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        display: none;
    }
}

.article-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.article-title-h1 {
    color: #010606;
    margin-bottom: 50px;
    font-size: 40px;
}

.article-title-h2 {
    color: #010606;
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 40px;
}

.article-p {
    margin-left: 200px;
    color: #010606;
    justify-content: left;
    align-items: left;
    text-align: left;

    @media (max-width: 768px) {
        margin-left: 0px;
    }
}
