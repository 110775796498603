.main-dev {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 11rem 0rem;
    background: #f7f8fa;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
    /* padding: 1.5rem 2rem; */
    text-rendering: optimizeLegibility;
    
}




.cardGrid {
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit,300px);
}

.card {
    /* align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    min-height: 400px;
    width: 300px; */

    
    width: 300px;
    height: 370px;
    padding: 60px 30px;
    margin: 0px;
    background: #f2f3f7;
    box-shadow: 0.6em 0.6em 1.2em #d2dce9,
        -0.5em -0.5em 1em #ffffff;
    border-radius: 20px;
}

.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }

.imgBx
{
   width: 180px;
   height: 180px;
   border-radius: 50%;
   position: relative;
   margin-bottom: 20px;
   overflow: hidden;
}

.member-img
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
}



.title-h1 {
    font-family: "Encode Sans Expanded", sans-serif;
    margin-bottom: 50px;
    /* font-size: 40px; */
    color: #010606;
}

.title-h5
{
   color: #010606;
   font-size: 0.9rem;
   font-weight: bold;
   text-align: center;
   letter-spacing: 0.5px;
}

.title-h6
{
   color: #010606;
   font-size: 0.7rem;
   font-weight: bold;
   text-align: center;
}