.blog-wrap {
    max-width: 1000px;
    margin: 0 auto;
    padding: 11rem 0rem;
}

.blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #000000;
    font-weight: 500;
    display: block;
}

.blog-wrap header {
    text-align: center;
}

.blog-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
}

.blog-wrap img {
    width: 100%;
}

.blog-wrap .blog-picture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.blog-subCategory {
    display: flex;
    justify-content: center;
}

.blog-subCategory>div {
    margin: 1rem;
}

.blog-desc {
    padding: 1rem;
    margin-top: 1.5rem;
}

.blog-desc-2 {
    padding: 1rem;
    /* margin-top: 1.5rem; */
}