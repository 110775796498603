.chip {
  font-size: 0.7rem;
  /* background: linear-gradient(to right, #6190e8, #a7bfe8); */
  background: linear-gradient(to right, #17eb93, #52b1c9);

  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
}
